.container {
  background: #f8f7fa;
  flex: 1;
  min-height: calc(100vh - 90px);
  user-select: none;
  padding-bottom: 40px;
}

.headerBlock {
  background-image: url("./../../../assets/images/bg3.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  display: flex;
}

.headerBlockTexts {
  max-width: 1024px;
}

.headerBlockTexts h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.bodyWebinars {
  position: relative;
  padding: 55px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  top: -250px;
  margin: 0 100px;
  min-height: 700px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  max-width: 675px;
  width: 100%;
}

.rightColumn {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  width: 100%;
  margin-left: 25px;
}

.webinarBtn {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  padding: 20px;
  cursor: pointer;
  min-width: 400px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.webinarBtn:hover {
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.2);
}

.bodyWebinars {
  display: flex;
  padding: 58px 23px 58px 52px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin: 56px auto;
  max-width: 1224px;
}

.submitBtn {
  width: 100%;
  margin-top: 25px;
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  margin-left: auto;
}

.submitBtn:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.submitBtn:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.video {
  width: 676px;
  height: 424px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.videoPreview {
  min-width: 132px;
  width: 132px;
  height: 83px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;
}

.subTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 29px;
  color: #3a3a3a;
  margin-top: 40px;
}

.loaderContainer {
  min-height: calc(50vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
}

.timeCurrent {
  margin-top: 30px;
}
.timeCurrent > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}


.timeCurrentPreview > span:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #3a3a3a;
  margin-left: 5px;
}

.timeCurrent > span:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #a548ff;
  margin-left: 10px;
}

.speakerCurrent > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

.speakerCurrent > span:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #3a3a3a;
  margin-left: 10px;
}

.zoomCurrent {
  margin-top: 30px;
}

.zoomCurrent > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

.zoomCurrent > a {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #a548ff;
  margin-left: 10px;
}
