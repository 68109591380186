.container {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(26px);
  border-radius: 16px;
  max-width: 585px;
  padding: 48px 100px 68px;
  margin: auto;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 107.7%;
  text-align: center;
  color: #000000;
}

.form {
  display: flex;
  flex-direction: column;
}
.label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
}

.input {
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.inputError {
  border: 3px solid red;
}

.submitBtn {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 50px;
  padding: 16px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.submitBtn:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.submitBtn:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.checkBoxText {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
}

.checkBoxBlock {
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.checkBox {
  align-items: center;
  display: flex;
}

.fogotPass {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #a548ff;
  transition: 0.5s;
}

.fogotPass:hover {
  color: #ffffff;
}

.passwordBlock {
  width: 100%;
  display: flex;
  position: relative;
}

.passwordBlock > input {
  width: 100%;
}

.showPassIcon {
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
}
