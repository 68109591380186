.courseItemInfoBlock {
  flex-direction: column;
  display: flex;
  margin-left: 50px;
  width: 100%;
  max-width: 1350px;
}

.courseItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #3a3a3a;
  max-width: 950px;
}

.courseItemInfo {
  flex-direction: row;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
}

.infoItem {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
}

.infoItem span:not(:first-child) {
  margin-left: 15px;
}

.courseBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.courseInfoContainer {
  padding: 30px 67px 64px;
  background-color: #ffffff;
  max-width: 1024px;
  width: 1024px;
  position: relative;
  border-radius: 16px;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  top: -100px;
}
.descriptionBody {
  width: 100%;
  max-width: 1024px;
}

.descriptionBody h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3a3a3a;
}

.editor {
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 0px 10px;
  outline: none;
}

.descriptionBlock {
  margin-bottom: 50px;
  font-size: 18px;
}

.projList {
  padding-left: 25px;
}

.inputError {
  border: 3px solid red;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 25px 320px 50px 190px;
}

.formLection {
  display: flex;
  padding: 58px 23px 58px 52px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin: 56px auto;
  max-width: 1224px;
}

.submitBtn {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 10px;
  padding: 16px 100px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.submitBtn:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.submitBtn:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.subTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-top: 40px;
}

.linkBtn {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160.9%;
  color: #a548ff;
}

.linkBtn:hover {
  color: #ffffff;
}

.linkBtn:focus {
  color: #a548ff;
}

.video {
  width: 676px;
  height: 424px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.video span {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 34px;
  color: #3a3a3a;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  max-width: 675px;
}

.addFile {
  color: #a548ff;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 100px;
}

.addedFile {
  color: rgba(58, 58, 58, 1);
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 40px;
  width: 100%;
}

.addFile div {
  width: 100%;
  margin-left: 20px;
}

.addedFile div {
  width: 100%;
  margin-left: 20px;
}

.rightColumn {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  margin-left: 25px;
}

.lectureBtn {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  padding: 30px;
  cursor: pointer;
  min-width: 400px;
}

.checkBox {
  margin: 100px 0 60px;
  align-items: center;
  display: flex;
}

.practice {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-left: 24px;
}

.loaderContainer {
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.addImage {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #ffffff;
  cursor: pointer;
}

.deleteBtn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
