.header {
  background: #ffffff !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  padding: 23px 100px !important;
  height: 90px !important;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.navItem:first-child {
  margin-right: 15px;
}

.navItem {
  padding: 12px 25px;
  border: 3px solid #a548ff;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #a548ff;
  transition: 0.5s;
}

.navItemSelected,
.navItem:hover {
  background: #a548ff;
  color: #ffffff;
}

.navItemLogin {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  margin: 0 15px;
  padding: 0 15px;
  padding-bottom: 35px;
}

.navItemLogin:hover {
  color: #a548ff;
}

.navItemLoginSelected {
  border-bottom: 4px solid #a548ff;
}

.userName {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  cursor: pointer;
  align-items: center;
  display: flex;
}
