.container {
  background: #f8f7fa;
  flex: 1;
  min-height: calc(100vh - 90px);
}

.headerBlock {
  background-image: url("./../../../assets/images/coursesHeader.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 355px;
  padding: 100px;
  min-width: 100%;
  margin-top: 90px;
}

.headerBlockTexts {
  max-width: 570px;
}

.headerBlockTexts h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  color: #000000;
}

.headerBlockTexts h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 129.2%;
  color: #000000;
}

.coursesBlock {
  padding: 40px 100px;
}

.courseItem {
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  transition: linear 0.2s;
}

.courseItemStudent {
  cursor: pointer;
}

.courseItemStudent:hover {
  box-shadow: 20px -15px 10px 5px rgba(0, 0, 0, 0.2);
}

.courseItem:first-child {
  margin-top: 0;
}

.courseImage {
  min-width: 250px;
  max-width: 250px;
  height: 130px;
  resize: contain;
  border-radius: 26px;
}

.courseItemInfoBlock {
  flex-direction: column;
  display: flex;
  margin-left: 50px;
  width: 100%;
  max-width: 1350px;
}

.tableProgressBar {
  background: #ededed;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  height: 10px;
  margin-top: 15px;
}

.tableProgressBarInside {
  background: linear-gradient(0deg, #83d35d, #83d35d), #ededed;
  border-radius: 6px;
  width: 0%;
  max-width: 360px;
  height: 10px;
}

.progressBlock {
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
}

.courseItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #3a3a3a;
  max-width: 950px;
}

.courseItemInfo {
  flex-direction: row;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.infoItem {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
  flex-basis: 30%;
}

.infoItem span:not(:first-child) {
  margin-left: 15px;
}

.submitBtn {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  margin-left: auto;
  min-width: 220px;
}

.newCourseBlock {
  margin: 79px 0;
}

.newCourse {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  min-width: 222px;

  box-shadow: 0px 19px 31px rgba(206, 11, 255, 0.25);
}

.newCourse:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.newCourse:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.submitBtn:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.submitBtn:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.loaderContainer {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
