@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Philosopher:wght@400;700&display=swap");

* {
  font-family: "Montserrat";
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-off {
  overflow: hidden;
}

/* width */
body::-webkit-scrollbar {
  width: 3px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #a548ff;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #a548ff;
}

code {
  font-family: "Montserrat";
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #a548ff !important;
  border-color: #a548ff !important;
}

.ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
  border: 3px solid #a9a9a9 !important;
  background: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 3px solid #a548ff !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.3) translate(-50%, -50%) !important;
}

.formError {
  font-size: 12;
  font-weight: 500;
  color: red;
  padding-left: 10px;
}

.ant-spin-dot-item {
  background-color: #a548ff !important;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: none !important;
}
