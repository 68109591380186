.container {
  background: #f8f7fa;
  flex: 1;
  min-height: calc(100vh - 90px);
  user-select: none;
  padding-bottom: 40px;
}

.headerBlock {
  background-image: url("./../../../assets/images/bg3.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  display: flex;
}

.headerBlockTexts {
  max-width: 1024px;
}

.headerBlockTexts h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.bodyWebinars {
  position: relative;
  padding: 55px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  top: -200px;
  margin: 0 100px;
}

.bodyWebinars {
  margin-top: 30px;
}

.loaderContainer {
  min-height: calc(50vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.courseItem {
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  transition: linear 0.2s;
}

.courseItemStudent {
  cursor: pointer;
}

.courseItemStudent:hover {
  box-shadow: 20px -15px 10px 5px rgba(0, 0, 0, 0.2);
}

.courseItem:first-child {
  margin-top: 0;
}

.courseImage {
  min-width: 250px;
  height: 130px;
  resize: contain;
  border-radius: 26px;
}

.courseItemInfoBlock {
  flex-direction: column;
  display: flex;
  margin-left: 50px;
  width: 100%;
  max-width: 1350px;
}

.courseItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #3a3a3a;
  max-width: 950px;
}

.courseItemInfo {
  flex-direction: row;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.infoItem {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
  flex-basis: 30%;
}

.infoItem span:not(:first-child) {
  margin-left: 15px;
}

.submitBtn {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  margin-left: auto;
  margin-top: 22px;
}

.projectItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3a3a3a;
}

.projectItem {
  margin: 29px 0 56px;
}

.submitBtn:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.submitBtn:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.projectNameBLock {
  flex-direction: row;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.readyProject {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 129.2%;
  color: #5bbe2c;
}
