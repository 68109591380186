.courseBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 90px;
}

.lecturesBlock {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  position: relative;
  min-height: 500px;
  top: -200px;
  max-width: 1224px;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  padding: 58px 20px 58px 52px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  max-width: 675px;
  width: 100%;
}

.rightColumn {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  width: 100%;
  margin-left: 25px;
}

.lectureItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;
  padding: 12px;
  cursor: pointer;
}

.lectureItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  margin-left: 20px;
}

.lectureTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3a3a3a;
  margin-top: 53px;
}

.lectureDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
}

.submitBtn {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 30px;
  padding: 16px 100px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.examLink {
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #3a3a3a;
}

.examLink:hover {
  color: #3a3a3a;
  text-decoration: none;
}

.submitBtn:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.submitBtn:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 570px;
}

.modalTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #3a3a3a;
}

.modalDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.2%;
  text-align: center;
  color: #3a3a3a;
  margin-top: 15px;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
}

.input {
  width: 100%;
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.form {
  width: 100%;
  margin-top: 30px;
}

.textarea {
  max-height: 300px;
}