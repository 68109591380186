@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Philosopher:wght@400;700&display=swap);
* {
  font-family: "Montserrat";
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-off {
  overflow: hidden;
}

/* width */
body::-webkit-scrollbar {
  width: 3px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #a548ff;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #a548ff;
}

code {
  font-family: "Montserrat";
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #a548ff !important;
  border-color: #a548ff !important;
}

.ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
  border: 3px solid #a9a9a9 !important;
  background: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 3px solid #a548ff !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.3) translate(-50%, -50%) !important;
}

.formError {
  font-size: 12;
  font-weight: 500;
  color: red;
  padding-left: 10px;
}

.ant-spin-dot-item {
  background-color: #a548ff !important;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: none !important;
}

.Header_header__1VCKf {
  background: #ffffff !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  padding: 23px 100px !important;
  height: 90px !important;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Header_navItem__38LDp:first-child {
  margin-right: 15px;
}

.Header_navItem__38LDp {
  padding: 12px 25px;
  border: 3px solid #a548ff;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #a548ff;
  transition: 0.5s;
}

.Header_navItemSelected__14Gjh,
.Header_navItem__38LDp:hover {
  background: #a548ff;
  color: #ffffff;
}

.Header_navItemLogin__1RJUg {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  margin: 0 15px;
  padding: 0 15px;
  padding-bottom: 35px;
}

.Header_navItemLogin__1RJUg:hover {
  color: #a548ff;
}

.Header_navItemLoginSelected__1gxBM {
  border-bottom: 4px solid #a548ff;
}

.Header_userName__2h_yj {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.RegisterScreen_container__2rDBl {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  border-radius: 16px;
  max-width: 585px;
  padding: 48px 100px 68px;
  margin: auto;
}

.RegisterScreen_title__1XuWL {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 107.7%;
  text-align: center;
  color: #000000;
}

.RegisterScreen_form__RroGk {
  display: flex;
  flex-direction: column;
}
.RegisterScreen_label__2EKSi {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
}

.RegisterScreen_input__2juDb {
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.RegisterScreen_inputError__1sRPd {
  border: 3px solid red;
}

.RegisterScreen_submitBtn__2hR1x {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 50px;
  padding: 16px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.RegisterScreen_submitBtn__2hR1x:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.RegisterScreen_submitBtn__2hR1x:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.RegisterScreen_checkBoxText__3ftwd {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
}

.RegisterScreen_checkBoxBlock__2kRAb {
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.RegisterScreen_checkBox__1vc36 {
  align-items: center;
  display: flex;
}

.RegisterScreen_fogotPass__3o3N2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #a548ff;
  transition: 0.5s;
}

.RegisterScreen_fogotPass__3o3N2:hover {
  color: #ffffff;
}

.RegisterScreen_terms__37DkL {
  align-items: center;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.165px;
  color: rgba(0, 0, 0, 0.75);
  width: 100%;
  margin-top: 22px;
}

.RegisterScreen_passwordBlock__3BSla {
  width: 100%;
  display: flex;
  position: relative;
}

.RegisterScreen_passwordBlock__3BSla > input {
  width: 100%;
}

.RegisterScreen_showPassIcon__-qW7c {
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
}

.LoginScreen_container__2C1ah {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  border-radius: 16px;
  max-width: 585px;
  padding: 48px 100px 68px;
  margin: auto;
}

.LoginScreen_title__1JVpQ {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 107.7%;
  text-align: center;
  color: #000000;
}

.LoginScreen_form__eBAyA {
  display: flex;
  flex-direction: column;
}
.LoginScreen_label__2ch0E {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
}

.LoginScreen_input__r8Chy {
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.LoginScreen_inputError__vAKAK {
  border: 3px solid red;
}

.LoginScreen_submitBtn__1a1pY {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 50px;
  padding: 16px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.LoginScreen_submitBtn__1a1pY:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.LoginScreen_submitBtn__1a1pY:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.LoginScreen_checkBoxText__2znEe {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
}

.LoginScreen_checkBoxBlock__2cRaK {
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.LoginScreen_checkBox__1l9Y4 {
  align-items: center;
  display: flex;
}

.LoginScreen_fogotPass__O8mMP {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #a548ff;
  transition: 0.5s;
}

.LoginScreen_fogotPass__O8mMP:hover {
  color: #ffffff;
}

.LoginScreen_passwordBlock__1Cb4O {
  width: 100%;
  display: flex;
  position: relative;
}

.LoginScreen_passwordBlock__1Cb4O > input {
  width: 100%;
}

.LoginScreen_showPassIcon__1eWPc {
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
}

.ResetPassword_container__3j_uW {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  border-radius: 16px;
  max-width: 585px;
  padding: 48px 100px 68px;
  margin: auto;
}

.ResetPassword_title__2FB2_ {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 107.7%;
  text-align: center;
  color: #000000;
}

.ResetPassword_form__vk0uN {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.ResetPassword_label__3WQ9O {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
}

.ResetPassword_input__3hL5m {
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.ResetPassword_inputError__22nVt {
  border: 3px solid red;
}

.ResetPassword_submitBtn__3KUg9 {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 50px;
  padding: 16px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.ResetPassword_submitBtn__3KUg9:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.ResetPassword_submitBtn__3KUg9:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.ResetPassword_checkBoxText__2zCGB {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
}

.ResetPassword_checkBoxBlock__30Keo {
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.ResetPassword_checkBox__2GQnN {
  align-items: center;
  display: flex;
}

.ResetPassword_fogotPass__3a35q {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #a548ff;
  transition: 0.5s;
  text-align: center;
}

.ResetPassword_fogotPass__3a35q:hover {
  color: #ffffff;
}

.ResetPassword_linkBlock__2Wj1- {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ResetPassword_passwordBlock__1m-I4 {
  width: 100%;
  display: flex;
  position: relative;
}

.ResetPassword_passwordBlock__1m-I4 > input {
  width: 100%;
}

.ResetPassword_showPassIcon__3TTUb {
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
}

.TermsScreen_container__yz4G5 {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  border-radius: 16px;
  max-width: 1024px;
  padding: 48px 30px 68px;
  margin: auto;
}

.TermsScreen_title__1awfN {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 107.7%;
  text-align: center;
  color: #000000;
}

.TermsScreen_form__3Mo4z {
  display: flex;
  flex-direction: column;
}
.TermsScreen_label__2_Sgm {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
}

.TermsScreen_input__254j8 {
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.TermsScreen_inputError__jLvkq {
  border: 3px solid red;
}

.TermsScreen_submitBtn__2Oj-j {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 50px;
  padding: 16px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  width: 585px;
}

.TermsScreen_submitBtn__2Oj-j:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.TermsScreen_submitBtn__2Oj-j:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.TermsScreen_checkBoxText__28Zkf {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
}

.TermsScreen_checkBoxBlock__2Wcq4 {
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.TermsScreen_checkBox__1QzZS {
  align-items: center;
  display: flex;
}

.TermsScreen_fogotPass__1KRey {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #a548ff;
  transition: 0.5s;
}

.TermsScreen_fogotPass__1KRey:hover {
  color: #ffffff;
}

.CoursesScreen_container__1CQ9s {
  background: #f8f7fa;
  flex: 1 1;
  min-height: calc(100vh - 90px);
}

.CoursesScreen_headerBlock__2mPUQ {
  background-image: url(/static/media/coursesHeader.be669e0e.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 355px;
  padding: 100px;
  min-width: 100%;
  margin-top: 90px;
}

.CoursesScreen_headerBlockTexts__1W99d {
  max-width: 570px;
}

.CoursesScreen_headerBlockTexts__1W99d h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  color: #000000;
}

.CoursesScreen_headerBlockTexts__1W99d h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 129.2%;
  color: #000000;
}

.CoursesScreen_coursesBlock__2fiRI {
  padding: 40px 100px;
}

.CoursesScreen_courseItem__1ES-K {
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  transition: linear 0.2s;
}

.CoursesScreen_courseItemStudent__2ErXu {
  cursor: pointer;
}

.CoursesScreen_courseItemStudent__2ErXu:hover {
  box-shadow: 20px -15px 10px 5px rgba(0, 0, 0, 0.2);
}

.CoursesScreen_courseItem__1ES-K:first-child {
  margin-top: 0;
}

.CoursesScreen_courseImage__2nA6S {
  min-width: 250px;
  max-width: 250px;
  height: 130px;
  resize: contain;
  border-radius: 26px;
}

.CoursesScreen_courseItemInfoBlock__vUdfk {
  flex-direction: column;
  display: flex;
  margin-left: 50px;
  width: 100%;
  max-width: 1350px;
}

.CoursesScreen_tableProgressBar__1KME9 {
  background: #ededed;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  height: 10px;
  margin-top: 15px;
}

.CoursesScreen_tableProgressBarInside__3TcLk {
  background: linear-gradient(0deg, #83d35d, #83d35d), #ededed;
  border-radius: 6px;
  width: 0%;
  max-width: 360px;
  height: 10px;
}

.CoursesScreen_progressBlock__1_nWy {
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
}

.CoursesScreen_courseItemTitle__3MI5L {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #3a3a3a;
  max-width: 950px;
}

.CoursesScreen_courseItemInfo__3avGf {
  flex-direction: row;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.CoursesScreen_infoItem__2FX6D {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
  flex-basis: 30%;
}

.CoursesScreen_infoItem__2FX6D span:not(:first-child) {
  margin-left: 15px;
}

.CoursesScreen_submitBtn__2xrcU {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  margin-left: auto;
  min-width: 220px;
}

.CoursesScreen_newCourseBlock__cnLJj {
  margin: 79px 0;
}

.CoursesScreen_newCourse__1ryWV {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  min-width: 222px;

  box-shadow: 0px 19px 31px rgba(206, 11, 255, 0.25);
}

.CoursesScreen_newCourse__1ryWV:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.CoursesScreen_newCourse__1ryWV:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.CoursesScreen_submitBtn__2xrcU:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.CoursesScreen_submitBtn__2xrcU:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.CoursesScreen_loaderContainer__1geMp {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ProjectsScreen_container__2UBO6 {
  background: #f8f7fa;
  flex: 1 1;
  min-height: calc(100vh - 90px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 40px;
}

.ProjectsScreen_headerBlock__2xWO8 {
  background-image: url(/static/media/bg3.ee120390.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  display: flex;
}

.ProjectsScreen_headerBlockTexts__2LmS- {
  max-width: 1024px;
}

.ProjectsScreen_headerBlockTexts__2LmS- h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.ProjectsScreen_bodyWebinars__369f3 {
  position: relative;
  padding: 55px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  top: -200px;
  margin: 0 100px;
}

.ProjectsScreen_bodyWebinars__369f3 {
  margin-top: 30px;
}

.ProjectsScreen_loaderContainer__hmnrV {
  min-height: calc(50vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.ProjectsScreen_courseItem__3zzdV {
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  transition: linear 0.2s;
}

.ProjectsScreen_courseItemStudent__2qz5p {
  cursor: pointer;
}

.ProjectsScreen_courseItemStudent__2qz5p:hover {
  box-shadow: 20px -15px 10px 5px rgba(0, 0, 0, 0.2);
}

.ProjectsScreen_courseItem__3zzdV:first-child {
  margin-top: 0;
}

.ProjectsScreen_courseImage__2Fu3q {
  min-width: 250px;
  height: 130px;
  resize: contain;
  border-radius: 26px;
}

.ProjectsScreen_courseItemInfoBlock__boESh {
  flex-direction: column;
  display: flex;
  margin-left: 50px;
  width: 100%;
  max-width: 1350px;
}

.ProjectsScreen_courseItemTitle__2oegU {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #3a3a3a;
  max-width: 950px;
}

.ProjectsScreen_courseItemInfo__hpHQh {
  flex-direction: row;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.ProjectsScreen_infoItem__l2Kd3 {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
  flex-basis: 30%;
}

.ProjectsScreen_infoItem__l2Kd3 span:not(:first-child) {
  margin-left: 15px;
}

.ProjectsScreen_submitBtn__3Dt8V {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  margin-left: auto;
  margin-top: 22px;
}

.ProjectsScreen_projectItemTitle__14zoB {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3a3a3a;
}

.ProjectsScreen_projectItem__1CVHp {
  margin: 29px 0 56px;
}

.ProjectsScreen_submitBtn__3Dt8V:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.ProjectsScreen_submitBtn__3Dt8V:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.ProjectsScreen_projectNameBLock__2dK9t {
  flex-direction: row;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProjectsScreen_readyProject__O5-rN {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 129.2%;
  color: #5bbe2c;
}

.CourseCreateScreen_container__3JdJk {
  background: #f8f7fa;
  flex: 1 1;
  min-height: calc(100vh - 90px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 40px;
}

.CourseCreateScreen_headerBlock__2WgCd {
  background-image: url(/static/media/bg3.ee120390.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 240px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
}

.CourseCreateScreen_headerBlockTexts__3E1Sr {
  max-width: 1024px;
}

.CourseCreateScreen_headerBlockTexts__3E1Sr h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.CourseCreateScreen_breadCrumb__1OjXo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.CourseCreateScreen_breadCrumbLink__3LpjB {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.58);
}

.CourseCreateScreen_breadCrumbLink__3LpjB:hover {
  color: rgba(255, 255, 255, 1);
}

.CourseCreateScreen_breadCrumb__1OjXo span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 5px;
}

.CourseCreateScreen_navigationEdit__hT70v {
  padding: 30px 190px 0px;
  border-bottom: 1px solid #e3e3e3;
}

.CourseCreateScreen_editBtn__cRGJi {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-right: 46px;
  padding-bottom: 30px;
  cursor: pointer;
}

.CourseCreateScreen_editBtnСhoosed__1w6zT {
  border-bottom: 4px solid #a548ff;
}

.CourseCreateScreen_label__G2zab {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  margin-top: 25px;
}

.CourseCreateScreen_input__3sEiP {
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.CourseCreateScreen_editorWrapper__2R04g {
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  outline: none;
}

.CourseCreateScreen_editor__2HJ0d {
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 0px 10px;
  outline: none;
}

.CourseCreateScreen_inputError__7w79O {
  border: 3px solid red;
}

.CourseCreateScreen_form__3JTvQ {
  display: flex;
  flex-direction: column;
  padding: 25px 320px 50px 190px;
}

.CourseCreateScreen_formLection__sqgX6 {
  display: flex;
  padding: 58px 23px 58px 52px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin: 56px auto;
  max-width: 1224px;
}

.CourseCreateScreen_submitBtn__1oseA {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #A548FF;
  border-radius: 100px;
  margin-top: 50px;
  padding: 16px 100px;
  cursor: pointer;
  border: 3px solid #A548FF;
  outline: none;
  transition: 0.5s;
}

.CourseCreateScreen_submitBtn__1oseA:hover {
  background: #ffffff;
  border: 3px solid #A548FF;
  color: #A548FF;
}

.CourseCreateScreen_submitBtn__1oseA:focus {
  background: #A548FF;
  border: 3px solid #A548FF;
  color: #ffffff;
}

.CourseCreateScreen_subTitle__15ru4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-top: 40px;
}

.CourseCreateScreen_linkBtn__3v_VX {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160.9%;
  color: #a548ff;
}

.CourseCreateScreen_linkBtn__3v_VX:hover {
  color: #ffffff;
}

.CourseCreateScreen_linkBtn__3v_VX:focus {
  color: #a548ff;
}

.CourseCreateScreen_video__2W9as {
  width: 676px;
  height: 424px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.CourseCreateScreen_video__2W9as span {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 34px;
  color: #3a3a3a;
}

.CourseCreateScreen_leftColumn__378ED {
  display: flex;
  flex-direction: column;
  max-width: 675px;
}

.CourseCreateScreen_addFile__3T0x3 {
  color: #a548ff;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}

.CourseCreateScreen_addedFile__3ZWi2 {
  color: rgba(58, 58, 58, 1);
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 40px;
  width: 100%;
}

.CourseCreateScreen_addFile__3T0x3 div {
  width: 100%;
  margin-left: 20px;
}

.CourseCreateScreen_addedFile__3ZWi2 div {
  width: 100%;
  margin-left: 20px;
}

.CourseCreateScreen_rightColumn__2OkdR {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  margin-left: 25px;
}

.CourseCreateScreen_lectureBtn__PENo7 {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  padding: 30px;
  cursor: pointer;
  min-width: 400px;
}

.CourseCreateScreen_checkBox__7eOjl {
  margin: 100px 0 60px;
  align-items: center;
  display: flex;
}

.CourseCreateScreen_practice__20qWS {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-left: 24px;
}

.CourseCreateScreen_loaderContainer__2e6tD {
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.CourseCreateScreen_addImage__2D7LU {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #ffffff;
  cursor: pointer;
}

.CourseCreateScreen_deleteBtn__1jF3a {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.StudentsScreen_container__3KuAD {
  background: #f8f7fa;
  flex: 1 1;
  min-height: calc(100vh - 90px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 40px;
}

.StudentsScreen_headerBlock__K_xGe {
  background-image: url(/static/media/bg3.ee120390.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 240px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  display: flex;
  align-items: center;
}

.StudentsScreen_headerBlockTexts__bUTr_ {
  max-width: 1024px;
}

.StudentsScreen_headerBlockTexts__bUTr_ h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.StudentsScreen_tableHeader__1Pkq7 {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 30px 100px;
  border-bottom: 1px solid #ececec;
}

.StudentsScreen_tableHeaderColumn__dwxPh {
  flex-basis: 16.5%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.165px;
  color: #21222b;
}

.StudentsScreen_tableBody__2DDPc {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #ececec;
}

.StudentsScreen_tableBodyTop__3TwGG {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 100px;
}

.StudentsScreen_tableBodyBottom__3mh7D {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 100px;
  background-color: #ffffff;
  flex-wrap: wrap;
}

.StudentsScreen_tableBodyColumn__3SAl7 {
  flex-basis: 16.5%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.StudentsScreen_tableProgress__Zi8yc {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 15px 10px 0;
  flex-basis: 14.5%;
}

.StudentsScreen_tableProgressPercent__2mm8W {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.165px;
  color: #000000;
}

.StudentsScreen_tableProgressBar__2DqZ7 {
  background: #ededed;
  border-radius: 6px;
  width: 100%;
  max-width: 160px;
  height: 10px;
  margin-top: 15px;
}

.StudentsScreen_tableProgressBarInside__l2n9P {
  background: linear-gradient(0deg, #83d35d, #83d35d), #ededed;
  border-radius: 6px;
  width: 0%;
  max-width: 160px;
  height: 10px;
}

.StudentsScreen_projectBlock__3YQbF {
  margin: 10px 15px;
}

.StudentsScreen_projectBlockTitle__1ttiV {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.165px;
  color: #000000;
}

.StudentsScreen_projectBlockBtn__12_LS {
  background: none;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129.2%;
  color: #cbcbcb;
  padding: 0;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.WebinaresScreen_container__38gcM {
  background: #f8f7fa;
  flex: 1 1;
  min-height: calc(100vh - 90px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 40px;
}

.WebinaresScreen_headerBlock__W-vDB {
  background-image: url(/static/media/bg3.ee120390.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  display: flex;
}

.WebinaresScreen_headerBlockTexts__3-bS_ {
  max-width: 1024px;
}

.WebinaresScreen_headerBlockTexts__3-bS_ h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.WebinaresScreen_bodyWebinars__3pkEZ {
  position: relative;
  padding: 55px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  top: -250px;
  margin: 0 100px;
  min-height: 700px;
}

.WebinaresScreen_leftColumn__1483G {
  display: flex;
  flex-direction: column;
  max-width: 675px;
  width: 100%;
}

.WebinaresScreen_rightColumn__Pw-Nr {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  width: 100%;
  margin-left: 25px;
}

.WebinaresScreen_webinarBtn__24zk_ {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  padding: 20px;
  cursor: pointer;
  min-width: 400px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.WebinaresScreen_webinarBtn__24zk_:hover {
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.2);
}

.WebinaresScreen_bodyWebinars__3pkEZ {
  display: flex;
  padding: 58px 23px 58px 52px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin: 56px auto;
  max-width: 1224px;
}

.WebinaresScreen_submitBtn__1XUY- {
  width: 100%;
  margin-top: 25px;
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  padding: 16px 50px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
  margin-left: auto;
}

.WebinaresScreen_submitBtn__1XUY-:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.WebinaresScreen_submitBtn__1XUY-:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.WebinaresScreen_video__2lx6T {
  width: 676px;
  height: 424px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.WebinaresScreen_videoPreview__2xYXf {
  min-width: 132px;
  width: 132px;
  height: 83px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;
}

.WebinaresScreen_subTitle__F8Ay3 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 29px;
  color: #3a3a3a;
  margin-top: 40px;
}

.WebinaresScreen_loaderContainer__12AbQ {
  min-height: calc(50vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.WebinaresScreen_description__2vyPR {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
}

.WebinaresScreen_timeCurrent__q8IDc {
  margin-top: 30px;
}
.WebinaresScreen_timeCurrent__q8IDc > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}


.WebinaresScreen_timeCurrentPreview__3p61T > span:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #3a3a3a;
  margin-left: 5px;
}

.WebinaresScreen_timeCurrent__q8IDc > span:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #a548ff;
  margin-left: 10px;
}

.WebinaresScreen_speakerCurrent__hiCdM > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

.WebinaresScreen_speakerCurrent__hiCdM > span:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #3a3a3a;
  margin-left: 10px;
}

.WebinaresScreen_zoomCurrent__2gkkB {
  margin-top: 30px;
}

.WebinaresScreen_zoomCurrent__2gkkB > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

.WebinaresScreen_zoomCurrent__2gkkB > a {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #a548ff;
  margin-left: 10px;
}

.WebinaresCreateScreen_container__SRDEN {
  background: #f8f7fa;
  flex: 1 1;
  min-height: calc(100vh - 90px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 40px;
}

.WebinaresCreateScreen_headerBlock__2-n61 {
  background-image: url(/static/media/bg3.ee120390.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 240px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  display: flex;
  align-items: center;
}

.WebinaresCreateScreen_headerBlockTexts__2eAg8 {
  max-width: 1024px;
}

.WebinaresCreateScreen_headerBlockTexts__2eAg8 h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.WebinaresCreateScreen_breadCrumb__3pYqp {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.WebinaresCreateScreen_breadCrumbLink__1y9PB {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.58);
}

.WebinaresCreateScreen_breadCrumbLink__1y9PB:hover {
  color: rgba(255, 255, 255, 1);
}

.WebinaresCreateScreen_breadCrumb__3pYqp span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 5px;
}

.WebinaresCreateScreen_navigationEdit__kPRWs {
  padding: 30px 190px 0px;
  border-bottom: 1px solid #e3e3e3;
}

.WebinaresCreateScreen_editBtn__1CtoU {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-right: 46px;
  padding-bottom: 30px;
  cursor: pointer;
}

.WebinaresCreateScreen_editBtnСhoosed__NWdz7 {
  border-bottom: 4px solid #a548ff;
}

.WebinaresCreateScreen_label__14zjQ {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  margin-top: 25px;
}

.WebinaresCreateScreen_input__JyuK3 {
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.WebinaresCreateScreen_editorWrapper__3a5n5 {
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  outline: none;
}

.WebinaresCreateScreen_editor__2yXkK {
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 0px 10px;
  outline: none;
}

.WebinaresCreateScreen_inputError__29zQ7 {
  border: 3px solid red;
}

.WebinaresCreateScreen_form__v1Us2 {
  display: flex;
  flex-direction: column;
  padding: 25px 320px 50px 190px;
}

.WebinaresCreateScreen_formLection__1oM4L {
  display: flex;
  padding: 58px 23px 58px 52px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin: 56px auto;
  max-width: 1224px;
}

.WebinaresCreateScreen_submitBtn__6_bag {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #A548FF;
  border-radius: 100px;
  margin-top: 50px;
  padding: 16px 100px;
  cursor: pointer;
  border: 3px solid #A548FF;
  outline: none;
  transition: 0.5s;
}

.WebinaresCreateScreen_submitBtn__6_bag:hover {
  background: #ffffff;
  border: 3px solid #A548FF;
  color: #A548FF;
}

.WebinaresCreateScreen_submitBtn__6_bag:focus {
  background: #A548FF;
  border: 3px solid #A548FF;
  color: #ffffff;
}

.WebinaresCreateScreen_subTitle__2FUjg {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-top: 40px;
}

.WebinaresCreateScreen_linkBtn__2TfI- {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160.9%;
  color: #a548ff;
}

.WebinaresCreateScreen_linkBtn__2TfI-:hover {
  color: #ffffff;
}

.WebinaresCreateScreen_linkBtn__2TfI-:focus {
  color: #a548ff;
}

.WebinaresCreateScreen_video__ow0XQ {
  width: 676px;
  height: 424px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.WebinaresCreateScreen_video__ow0XQ span {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 34px;
  color: #3a3a3a;
}

.WebinaresCreateScreen_leftColumn__tsqff {
  display: flex;
  flex-direction: column;
  max-width: 675px;
}

.WebinaresCreateScreen_addFile__pPYKb {
  color: #a548ff;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
  margin-top: 100px;
}

.WebinaresCreateScreen_addedFile__3K6PZ {
  color: rgba(58, 58, 58, 1);
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 40px;
  width: 100%;
}

.WebinaresCreateScreen_addFile__pPYKb div {
  width: 100%;
  margin-left: 20px;
}

.WebinaresCreateScreen_addedFile__3K6PZ div {
  width: 100%;
  margin-left: 20px;
}

.WebinaresCreateScreen_rightColumn__3MaSD {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  margin-left: 25px;
}

.WebinaresCreateScreen_lectureBtn__1ZNC9 {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  padding: 30px;
  cursor: pointer;
  min-width: 400px;
}

.WebinaresCreateScreen_checkBox__3ZHGE {
  margin: 100px 0 60px;
  align-items: center;
  display: flex;
}

.WebinaresCreateScreen_practice__iXpHX {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-left: 24px;
}

.WebinaresCreateScreen_loaderContainer__1OO9d {
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.WebinaresCreateScreen_addImage__viVP_ {
  color: #ffffff;
  cursor: pointer;
}

.CourseOpenScreen_container__3Jx5C {
  background: #f8f7fa;
  flex: 1 1;
  min-height: calc(100vh - 90px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 40px;
}

.CourseOpenScreen_headerBlock__1Se1W {
  background-image: url(/static/media/bg3.ee120390.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 440px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  padding-bottom: 205px;
}

.CourseOpenScreen_headerBlockTexts__3WuE- {
  max-width: 1024px;
}

.CourseOpenScreen_headerBlockTexts__3WuE- h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.CourseOpenScreen_headerBlockTexts__3WuE- h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 24;
  line-height: 36px;
  color: #ffffff;
}

.CourseOpenScreen_breadCrumb__39WMQ {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.CourseOpenScreen_breadCrumbLink__J02oP {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.58);
}

.CourseOpenScreen_breadCrumbLink__J02oP:hover {
  color: rgba(255, 255, 255, 1);
}

.CourseOpenScreen_breadCrumb__39WMQ span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 5px;
}

.CourseOpenScreen_navigationEdit__1rSKA {
  padding: 30px 190px 0px;
  border-bottom: 1px solid #e3e3e3;
}

.CourseOpenScreen_editBtn__3_aMc {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-right: 46px;
  padding-bottom: 30px;
  cursor: pointer;
}

.CourseOpenScreen_editBtnСhoosed__2wVPx {
  border-bottom: 4px solid #a548ff;
}

.CourseOpenScreen_label__2XGKE {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  margin-top: 25px;
}

.CourseOpenScreen_input__2F5Aq {
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.CourseOpenScreen_editorWrapper__2p7pX {
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  outline: none;
}

.CourseOpenScreen_courseItemInfoBlock__3A6x9 {
  flex-direction: column;
  display: flex;
  margin-left: 50px;
  width: 100%;
  max-width: 1350px;
}

.CourseOpenScreen_courseItemTitle__3SRsd {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #3a3a3a;
  max-width: 950px;
}

.CourseOpenScreen_courseItemInfo__3_JUq {
  flex-direction: row;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
}

.CourseOpenScreen_infoItem__1VJeq {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
}

.CourseOpenScreen_infoItem__1VJeq span:not(:first-child) {
  margin-left: 15px;
}

.CourseOpenScreen_courseBody__3fHWo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.CourseOpenScreen_courseInfoContainer__3IMYX {
  padding: 30px 67px 64px;
  background-color: #ffffff;
  max-width: 1024px;
  width: 1024px;
  position: relative;
  border-radius: 16px;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  top: -100px;
}
.CourseOpenScreen_descriptionBody__Gb1yo {
  width: 100%;
  max-width: 1024px;
}

.CourseOpenScreen_descriptionBody__Gb1yo h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3a3a3a;
}

.CourseOpenScreen_editor__Ky_ko {
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 0px 10px;
  outline: none;
}

.CourseOpenScreen_descriptionBlock__1nnfl {
  margin-bottom: 50px;
  font-size: 18px;
}

.CourseOpenScreen_projList__xO904 {
  padding-left: 25px;
}

.CourseOpenScreen_inputError__3cuEB {
  border: 3px solid red;
}

.CourseOpenScreen_form__3BDEn {
  display: flex;
  flex-direction: column;
  padding: 25px 320px 50px 190px;
}

.CourseOpenScreen_formLection__3szSP {
  display: flex;
  padding: 58px 23px 58px 52px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin: 56px auto;
  max-width: 1224px;
}

.CourseOpenScreen_submitBtn__2XxKA {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 10px;
  padding: 16px 100px;
  cursor: pointer;
  border: 3px solid #a548ff;
  box-shadow: 0px 4px 31px rgba(206, 11, 255, 0.74);
  outline: none;
  transition: 0.5s;
}

.CourseOpenScreen_submitBtn__2XxKA:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.CourseOpenScreen_submitBtn__2XxKA:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.CourseOpenScreen_subTitle__10i5t {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-top: 40px;
}

.CourseOpenScreen_linkBtn__2CsGJ {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160.9%;
  color: #a548ff;
}

.CourseOpenScreen_linkBtn__2CsGJ:hover {
  color: #ffffff;
}

.CourseOpenScreen_linkBtn__2CsGJ:focus {
  color: #a548ff;
}

.CourseOpenScreen_video__3E9aZ {
  width: 676px;
  height: 424px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.CourseOpenScreen_video__3E9aZ span {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 34px;
  color: #3a3a3a;
}

.CourseOpenScreen_leftColumn__39uN_ {
  display: flex;
  flex-direction: column;
  max-width: 675px;
}

.CourseOpenScreen_addFile__1yGoh {
  color: #a548ff;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
  margin-top: 100px;
}

.CourseOpenScreen_addedFile__W8Nb2 {
  color: rgba(58, 58, 58, 1);
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 40px;
  width: 100%;
}

.CourseOpenScreen_addFile__1yGoh div {
  width: 100%;
  margin-left: 20px;
}

.CourseOpenScreen_addedFile__W8Nb2 div {
  width: 100%;
  margin-left: 20px;
}

.CourseOpenScreen_rightColumn__k7_tQ {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  margin-left: 25px;
}

.CourseOpenScreen_lectureBtn__2ed0x {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  padding: 30px;
  cursor: pointer;
  min-width: 400px;
}

.CourseOpenScreen_checkBox__IASU9 {
  margin: 100px 0 60px;
  align-items: center;
  display: flex;
}

.CourseOpenScreen_practice__Z4y1y {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-left: 24px;
}

.CourseOpenScreen_loaderContainer__3VVkb {
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.CourseOpenScreen_addImage__365Bw {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #ffffff;
  cursor: pointer;
}

.CourseOpenScreen_deleteBtn__hvqea {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.CoursePreview_courseItemInfoBlock__2MbSA {
  flex-direction: column;
  display: flex;
  margin-left: 50px;
  width: 100%;
  max-width: 1350px;
}

.CoursePreview_courseItemTitle__2Ecc2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #3a3a3a;
  max-width: 950px;
}

.CoursePreview_courseItemInfo__3Sdab {
  flex-direction: row;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
}

.CoursePreview_infoItem__3mINF {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
}

.CoursePreview_infoItem__3mINF span:not(:first-child) {
  margin-left: 15px;
}

.CoursePreview_courseBody__Rr15O {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.CoursePreview_courseInfoContainer__2zm0m {
  padding: 30px 67px 64px;
  background-color: #ffffff;
  max-width: 1024px;
  width: 1024px;
  position: relative;
  border-radius: 16px;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  top: -100px;
}
.CoursePreview_descriptionBody__2FX_9 {
  width: 100%;
  max-width: 1024px;
}

.CoursePreview_descriptionBody__2FX_9 h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3a3a3a;
}

.CoursePreview_editor__1hjT2 {
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  color: #3a3a3a;
  padding: 0px 10px;
  outline: none;
}

.CoursePreview_descriptionBlock__FBTpE {
  margin-bottom: 50px;
  font-size: 18px;
}

.CoursePreview_projList__1ISrn {
  padding-left: 25px;
}

.CoursePreview_inputError__3AFwQ {
  border: 3px solid red;
}

.CoursePreview_form__JLV1r {
  display: flex;
  flex-direction: column;
  padding: 25px 320px 50px 190px;
}

.CoursePreview_formLection__3yyIq {
  display: flex;
  padding: 58px 23px 58px 52px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  margin: 56px auto;
  max-width: 1224px;
}

.CoursePreview_submitBtn__2LSzM {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 10px;
  padding: 16px 100px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.CoursePreview_submitBtn__2LSzM:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.CoursePreview_submitBtn__2LSzM:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.CoursePreview_subTitle__2BlyI {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-top: 40px;
}

.CoursePreview_linkBtn__1QJI9 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160.9%;
  color: #a548ff;
}

.CoursePreview_linkBtn__1QJI9:hover {
  color: #ffffff;
}

.CoursePreview_linkBtn__1QJI9:focus {
  color: #a548ff;
}

.CoursePreview_video__2mZti {
  width: 676px;
  height: 424px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.CoursePreview_video__2mZti span {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 34px;
  color: #3a3a3a;
}

.CoursePreview_leftColumn__3IaDW {
  display: flex;
  flex-direction: column;
  max-width: 675px;
}

.CoursePreview_addFile__2gEvp {
  color: #a548ff;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
  margin-top: 100px;
}

.CoursePreview_addedFile__1GToq {
  color: rgba(58, 58, 58, 1);
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 40px;
  width: 100%;
}

.CoursePreview_addFile__2gEvp div {
  width: 100%;
  margin-left: 20px;
}

.CoursePreview_addedFile__1GToq div {
  width: 100%;
  margin-left: 20px;
}

.CoursePreview_rightColumn__PCGtR {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  margin-left: 25px;
}

.CoursePreview_lectureBtn__1DZak {
  border: none;
  outline: none;
  background: none;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  padding: 30px;
  cursor: pointer;
  min-width: 400px;
}

.CoursePreview_checkBox__3HSsU {
  margin: 100px 0 60px;
  align-items: center;
  display: flex;
}

.CoursePreview_practice__1thwU {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #3a3a3a;
  margin-left: 24px;
}

.CoursePreview_loaderContainer__3epkY {
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f8f7fa;
}

.CoursePreview_addImage__1yMqE {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #ffffff;
  cursor: pointer;
}

.CoursePreview_deleteBtn__1xRMZ {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.CourseLectures_courseBody__6ZLNj {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 90px;
}

.CourseLectures_lecturesBlock__2IW01 {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  position: relative;
  min-height: 500px;
  top: -200px;
  max-width: 1224px;
  box-shadow: 0px 4px 25px rgba(129, 131, 143, 0.07);
  border-radius: 16px;
  padding: 58px 20px 58px 52px;
}

.CourseLectures_leftColumn__3KFTG {
  display: flex;
  flex-direction: column;
  max-width: 675px;
  width: 100%;
}

.CourseLectures_rightColumn__1XJg_ {
  flex-direction: column;
  display: flex;
  max-width: 410px;
  width: 100%;
  margin-left: 25px;
}

.CourseLectures_lectureItem__1nw3L {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;
  padding: 12px;
  cursor: pointer;
}

.CourseLectures_lectureItemTitle__1HF9g {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  margin-left: 20px;
}

.CourseLectures_lectureTitle__1ZcAU {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #3a3a3a;
  margin-top: 53px;
}

.CourseLectures_lectureDescription__2Oe7_ {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.2%;
  color: #3a3a3a;
}

.CourseLectures_submitBtn__1l2ZB {
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #a548ff;
  border-radius: 100px;
  margin-top: 30px;
  padding: 16px 100px;
  cursor: pointer;
  border: 3px solid #a548ff;
  outline: none;
  transition: 0.5s;
}

.CourseLectures_examLink__2hQaw {
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #3a3a3a;
}

.CourseLectures_examLink__2hQaw:hover {
  color: #3a3a3a;
  text-decoration: none;
}

.CourseLectures_submitBtn__1l2ZB:hover {
  background: #ffffff;
  border: 3px solid #a548ff;
  color: #a548ff;
}

.CourseLectures_submitBtn__1l2ZB:focus {
  background: #a548ff;
  border: 3px solid #a548ff;
  color: #ffffff;
}

.CourseLectures_modalBody__3ubdr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 570px;
}

.CourseLectures_modalTitle__14xh1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #3a3a3a;
}

.CourseLectures_modalDescription__VmXs1 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.2%;
  text-align: center;
  color: #3a3a3a;
  margin-top: 15px;
}

.CourseLectures_label__1rulL {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
}

.CourseLectures_input__jvi1D {
  width: 100%;
  border: 3px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  background: none;
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3a3a3a;
  padding: 15px 10px;
  outline: none;
}

.CourseLectures_form__1kWLC {
  width: 100%;
  margin-top: 30px;
}

.CourseLectures_textarea__3emVN {
  max-height: 300px;
}
.Navigation_containerPublic__2ifj2 {
  background-image: url(/static/media/bg4.08dd9b86.png);
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  align-items: center;
  display: flex;
  padding: 110px 0 50px;
}

.Navigation_loaderContainer__202Yo {
  display: flex;
  height: 100vh;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

