.container {
  background: #f8f7fa;
  flex: 1;
  min-height: calc(100vh - 90px);
  user-select: none;
  padding-bottom: 40px;
}

.headerBlock {
  background-image: url("./../../../assets/images/bg3.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 240px;
  height: auto;
  padding: 50px 100px;
  min-width: 100%;
  margin-top: 90px;
  position: relative;
  display: flex;
  align-items: center;
}

.headerBlockTexts {
  max-width: 1024px;
}

.headerBlockTexts h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 59px;
  color: #ffffff;
}

.tableHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 30px 100px;
  border-bottom: 1px solid #ececec;
}

.tableHeaderColumn {
  flex-basis: 16.5%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.165px;
  color: #21222b;
}

.tableBody {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #ececec;
}

.tableBodyTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 100px;
}

.tableBodyBottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 100px;
  background-color: #ffffff;
  flex-wrap: wrap;
}

.tableBodyColumn {
  flex-basis: 16.5%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.tableProgress {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 15px 10px 0;
  flex-basis: 14.5%;
}

.tableProgressPercent {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.165px;
  color: #000000;
}

.tableProgressBar {
  background: #ededed;
  border-radius: 6px;
  width: 100%;
  max-width: 160px;
  height: 10px;
  margin-top: 15px;
}

.tableProgressBarInside {
  background: linear-gradient(0deg, #83d35d, #83d35d), #ededed;
  border-radius: 6px;
  width: 0%;
  max-width: 160px;
  height: 10px;
}

.projectBlock {
  margin: 10px 15px;
}

.projectBlockTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.165px;
  color: #000000;
}

.projectBlockBtn {
  background: none;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129.2%;
  color: #cbcbcb;
  padding: 0;
  margin: 0 10px 0 0;
  cursor: pointer;
}
