.containerPublic {
  background-image: url("./../assets/images/bg4.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  align-items: center;
  display: flex;
  padding: 110px 0 50px;
}

.loaderContainer {
  display: flex;
  height: 100vh;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
}
